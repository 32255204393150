<template>
	<div class="opacity-page report-daily" v-if="loaded">
		<div class="header-filter">
			<div class="row-space-tbf search-row">
				<div class="space-left"></div>
				<div class="content">
					<div class="actions-page">
						<div class="filter-dropdown">
							<div class="label">Soft *</div>
							<div class="dropdown">
								<button class="btn-tbf white" id="dropdownFilterObjStatus" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<span class="text">{{ $t(`admin_users.soft_name.${selectedSoft}`) }}</span>

									<div class="icon-filter"><icon-arrow /></div>
								</button>
								<div class="dropdown-menu" aria-labelledby="dropdownFilterObjStatus">
									<template v-for="soft in listSofts">
										<div class="dropdown-item" @click="selectUniqueOptionFilter('selectedSoft', soft)" v-bind:class="{active: selectedSoft == soft}" :key="'filter-soft-' + soft">
											<div class="checkbox">
												<div class="checkmark"></div><span class="text">{{ $t(`admin_users.soft_name.${soft}`) }}</span>
											</div>
										</div>
									</template>
								</div>
							</div>
						</div>
						<div class="filter-dropdown">
							<div class="label">Abonamente</div>
							<div class="dropdown">
								<button class="btn-tbf white" id="dropdownFilterObjStatus" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<span class="text" v-if="!selectedPackages.length">{{ $t('admin_users.filters.by_packages') }}</span>
									<span class="text" v-else-if="selectedPackages.length == 1">Abonament: {{ $store.getters['packages/getBySoft'](selectedSoft).packages.find(el => el.unique_name == selectedPackages[0]).unique_name }}</span>
									<span class="text" v-else>{{ selectedPackages.length }} {{ $t('admin_users.filters.status_selected') }}</span>

									<div class="icon-filter" v-if="!selectedPackages.length"><icon-arrow /></div>
									<div class="icon-clear" @click.stop="clearFilter('selectedPackages')" v-else><icon-close /></div>
								</button>
								<div class="dropdown-menu" aria-labelledby="dropdownFilterObjStatus">
									<template v-for="package_item in $store.getters['packages/getBySoft'](selectedSoft).packages">
									<div class="dropdown-item" @click.stop="selectOptionFilter('selectedPackages', package_item.unique_name)" v-bind:class="{active: selectedPackages.includes(package_item.unique_name)}" :key="'filter-package-obj-' + package_item.id">
										<div class="checkbox">
											<div class="checkmark"></div><span class="text">{{ package_item.unique_name }}</span>
										</div>
									</div>
								</template>
								</div>
							</div>
						</div>
						<div class="filter-dropdown">
							<div class="label">De la ce status</div>
							<div class="dropdown">
								<button class="btn-tbf white" id="dropdownFilterObjStatus" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<span class="text" v-if="!selectedFromStatus.length">{{ $t('admin_users.filters.by_status') }}</span>
									<span class="text" v-else>{{ $t(`admin_users.status_soft.${selectedFromStatus}`) }}</span>

									<div class="icon-filter"><icon-arrow /></div>
								</button>
								<div class="dropdown-menu" aria-labelledby="dropdownFilterObjStatus">
									<template v-for="status in filterInstanceStatus">
										<div class="dropdown-item" @click="selectUniqueOptionFilter('selectedFromStatus', status)" v-bind:class="{active: selectedFromStatus == status}" :key="'filter-status-' + status">
											<div class="checkbox">
												<div class="checkmark"></div><span class="text">{{ $t(`admin_users.status_soft.${status}`) }}</span>
											</div>
										</div>
									</template>
								</div>
							</div>
						</div>
						<div class="filter-dropdown">
							<div class="label">La ce status *</div>
							<div class="dropdown">
								<button class="btn-tbf white" id="dropdownFilterObjStatus" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<span class="text">{{ $t(`admin_users.status_report_daily.${selectedInstanceStatus}`) }}</span>

									<div class="icon-filter"><icon-arrow /></div>
								</button>
								<div class="dropdown-menu" aria-labelledby="dropdownFilterObjStatus">
									<template v-for="status in filterInstanceStatus">
										<div class="dropdown-item" @click="selectUniqueOptionFilter('selectedInstanceStatus', status)" v-bind:class="{active: selectedInstanceStatus == status}" :key="'filter-status-' + status">
											<div class="checkbox">
												<div class="checkmark"></div><span class="text">{{ $t(`admin_users.status_report_daily.${status}`) }}</span>
											</div>
										</div>
									</template>
								</div>
							</div>
						</div>

						<div class="filter-dropdown">
							<div class="label">Perioada *</div>
							<date-range-picker
								ref="picker"
								class="calendar-filter"
								opens="left"
								:locale-data="calendarFilterSettings"
								:showWeekNumbers="false"
								:showDropdowns="false"
								:ranges="false"
								:autoApply="true"
								:linkedCalendars="true"
								minDate="2022-01-14"
								v-model="dateRange"
								@update="updateRangeFilter"
								>
								<template v-slot:input="picker">
									<div class="input-picker">
	                                    <div class="icon"><icon-date/></div>
										<div class="date" v-if="picker.startDate == picker.endDate">{{ picker.startDate | moment('DD MMM YYYY') }}</div>
										<div class="date" v-else>{{ picker.startDate | moment('DD MMM YYYY') }} - {{ picker.endDate | moment('DD MMM YYYY') }}</div>
										<icon-arrow class="icon-arrow right"/>
									</div>
								</template>
							</date-range-picker>
						</div>

						<div class="margin-left-auto">
							<div class="total-amount">
								<div class="label">Total perioada selectata</div>
								<div class="total">
									<div class="euro">{{ parseFloat(parseFloat(total_amount / 4.94).toFixed(2)) | numeral('0,0') }} &euro;</div>
									<div class="ron">{{ parseFloat(parseFloat(total_amount).toFixed(2)) | numeral('0,0') }} RON</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="space-right"></div>
			</div>
		</div>

		<div class="row-space-tbf header-charts">
			<div class="space-left"></div>
			<div class="content">
				<div class="chart-daily">
					<div class="title">Grafic schimbare de status</div>
					<div class="chart-box">
						<apexchart type="line" height="300" :options="optionsChart1" :series="seriesChart1"></apexchart>
					</div>
				</div>

				<div class="chart-daily">
					<div class="title">Grafic estimare venit</div>
					<div class="chart-box">
						<apexchart type="line" height="300" :options="optionsChart2" :series="seriesChart2"></apexchart>
					</div>
				</div>
			</div>
			<div class="space-right"></div>
		</div>

		<div class="tabel-report-users">
			<div class="total-entries row-space-tbf">
				<div class="space-left"></div>
				<div class="content">
					<div class="count-entries">{{ $t('admin_users.filters.total_entries') }}<span class="number">{{ report_users.length }}</span></div>
				</div>
				<div class="space-right"></div>
			</div>

			<div class="data-list list-users-report" v-if="report_users.length">
				<div class="row-space-tbf header-list">
					<div class="space-left"></div>
					<div class="content">
						<div class="column-filter column-master_inst_id">
							<div class="text">{{ $t('admin_users.mast_inst_id') }}</div>
						</div>
						<div class="column-filter column-name">
							<div class="text">{{ $t('admin_users.name') }}</div>
						</div>
						<div class="column-filter column-filter-status">
							<div class="text">{{ $t('admin_users.filter_status') }}</div>
						</div>
						<div class="column-filter column-current-status">
							<div class="text">{{ $t('admin_users.current_status') }}</div>
						</div>
						<div class="column-filter column-phone">
							<div class="text">{{ $t('admin_users.phone') }}</div>
						</div>
						<div class="column-filter column-change_status_at">
							<div class="text">{{ $t('admin_users.change_status_at') }}</div>
						</div>
					</div>
					<div class="space-right"></div>
				</div>

				<template v-for="user in report_users">
					<div class="row-space-tbf row-list-item" :key="user.id">
						<div class="space-left"><icon-people v-if="$resize && $mq.above(781)"/></div>
						<div class="content">
							<div class="border-hover" v-if="$resize && $mq.above(781)"></div>
							<div class="column-simple-text column-master_inst_id">
								<div class="text">{{ user.master_instance_id }}</div>
							</div>
							<div class="column-name-desc column-name link">
								<div class="name">{{ user.name }}</div>
								<div class="description">{{ user.email }}</div>
							</div>
							<div class="column-name-desc column-filter-status">
								<div class="name">{{ $t(`admin_users.status_soft.${user.from_status}`) }} -> {{ $t(`admin_users.status_soft.${user.status}`) }}</div>
								<div class="description">{{ user.package }}</div>
							</div>
							<div class="column-name-desc column-current-status">
								<div class="name">{{ $t(`admin_users.status_soft.${user.current_status}`) }}</div>
								<div class="description">{{ user.current_package }}</div>
							</div>
							<div class="column-simple-text column-phone">
								<div class="text">{{ user.phone }}</div>
							</div>
							<div class="column-simple-text column-change_status_at">
								<div class="text">{{ user.date | moment('DD MMM YYYY') }}</div>
							</div>
						</div>
						<div class="space-right"></div>
					</div>
				</template>
			</div>
			<div class="row-space-tbf list-empty" v-else>
				<div class="space-left"></div>
				<div class="content full">
					<div class="title">{{ $t('admin_users.empty_title')}}</div>
					<div class="description" v-html="$t('admin_users.empty_users_text')"></div>
				</div>
				<div class="space-right"></div>
			</div>
		</div>
	</div>
</template>

<script>
    import IconSearch from '../Icons/Search'
    import IconArrow from '../Icons/Arrow'
    import IconTrash from '../Icons/Trash'
    import IconUser from '../Icons/User'
    import IconPeople from '../Icons/People'
	import IconSettings from '../Icons/Settings'
	import IconClose from '../Icons/Close'
	import IconDate from '../Icons/Date'
    import LoaderItemsList from '../PagesLoaders/ItemsList'
	import InfiniteLoading from 'vue-infinite-loading'
	import VueApexCharts from 'vue-apexcharts'
	import { MonthPickerInput } from 'vue-month-picker'

    export default {
    	components: {
            IconSearch,
            IconArrow,
            IconTrash,
            IconUser,
            IconPeople,
			IconSettings,
			IconClose,
			IconDate,
           	LoaderItemsList, 
			InfiniteLoading,
			apexchart: VueApexCharts,
			MonthPickerInput
        },
        data() {
        	return {
        		loaded: false,
        		loadedList: false,
				last_updated: '',
        		selectedSoft: 'objective',
        		listSofts: ['objective', 'procedure', 'organigram'],
        		filterInstanceStatus: [ 'active', 'unpaid', 'grace_period', 'new'],
            	selectedInstanceStatus: 'active',
            	selectedFromStatus: '',
            	selectedPackages: [],
        		optionsChart1: {
        			stroke: {
        				width: [0, 0]
        			},
        			dataLabels: {
        				enabled: false
        			},
        			tooltip: {
        				style: {
        					fontSize: "12px",
        				},
        				y: {
        					formatter: function (val) {
        						return val;
        					},
        				},
        			},
        			xaxis: {
        				type: 'datetime',
        				labels: {
        					style: {
        						colors: '#747683',
        						fontSize: "12px",
        					},
        				},
        			},
        			yaxis: [{
        				title: {
        					text: 'Numar de conturi',
        				},
        				labels: {
        					style: {
        						colors: '#747683',
        						fontSize: "12px",
        					},
        				},
        			}],
        			grid: {
        				borderColor: '#F0F0F0',
        				strokeDashArray: 4,
        				yaxis: {
        					lines: {
        						show: true,
        					},
        				},
        			}
        		},
        		seriesChart1: [],
        		optionsChart2: {
        			stroke: {
        				width: [0, 0]
        			},
        			dataLabels: {
        				enabled: false
        			},
        			tooltip: {
        				style: {
        					fontSize: "12px",
        				},
        				y: {
        					formatter: function (val) {
        						return val;
        					},
        				},
        			},
        			xaxis: {
        				type: 'datetime',
        				labels: {
        					style: {
        						colors: '#747683',
        						fontSize: "12px",
        					},
        				},
        			},
        			yaxis: [{
        				title: {
        					text: 'Venit / Pierderi estimate',
        				},
        				labels: {
        					style: {
        						colors: '#747683',
        						fontSize: "12px",
        					},
        				},
        			}],
        			grid: {
        				borderColor: '#F0F0F0',
        				strokeDashArray: 4,
        				yaxis: {
        					lines: {
        						show: true,
        					},
        				},
        			},
        		},
        		seriesChart2: [],
            	month_date: {},
            	status_report: 'all_time',
            	dateRange: {
					startDate: moment().add(-5, 'days'),
					endDate: moment()
				},
				calendarFilterSettings: {
					firstDay: 1,
					format: 'DD-MM-YYYY',
					daysOfWeek:  moment.weekdaysMin(),
					monthNames: moment.monthsShort()
				},
				report_users: [],
				total_amount: 0
            }
        },
        watch: {

        },
        async mounted(){
        	await this.checkQueryFilters();

			setTimeout(() => {
				var title = 'Raport zilnic';
				this.$root.$emit("navbar_title", title);
			}, 0)

			var refreshIntervalApps = setInterval(() => {
				if(this.$store.state.packages.list.length){
					clearInterval(refreshIntervalApps);
					setTimeout(() => {
						this.getReportDaily()
					}, 200)
				}
			}, 200);

        },
        methods: {
        	checkQueryFilters() {
        		var dataQuery = Object.assign({}, this.$route.query);
				
				Object.keys(dataQuery).forEach(key => {
					if(key == 'status'){ this.selectedInstanceStatus = dataQuery[key] }
					if(key == 'from_status'){ this.selectedFromStatus = dataQuery[key] }
					if(key == 'packages'){ this.selectedPackages = dataQuery[key].split(',') }
					if(key == 'soft'){ this.selectedSoft = dataQuery[key] }
					if(key == 'start_date'){ this.dateRange.startDate = moment(dataQuery[key], 'DD-MM-YYYY') }
					if(key == 'end_date'){ this.dateRange.endDate = moment(dataQuery[key], 'DD-MM-YYYY') }
				});
        	},
        	getReportDaily() {
        		var paramsApi = this.queryObject()
        		this.seriesChart2 = []

        		axios.get('/overall-histories', { params: paramsApi })
        		.then(({data}) => {
        			this.seriesChart1 = [
	        			{
		        			name: "Conturi",
		        			type: 'column',
		        			data: data.data.graph_accounts,
	        			}
        			]

        			data.data.graph_package_amount.map(el => {
        				this.seriesChart2.push({name: el.name, type: 'column', data: Object.values(el.data)})
        			})

        			this.seriesChart2.push({ name: 'Total', type: 'line', data: data.data.graph_amount })
        			this.report_users = data.data.list
        			this.total_amount = data.data.total_amount
        		})
        		.finally(() => {
        			setTimeout(() => {
        				this.loaded = true
        				this.loadedList = true
        				setTimeout(() => {
        					$('.opacity-page').addClass('show')
        				}, 0)
        			}, 500)
        		})
        	},
			showModal(type, data = false){
				this.$root.$emit('open_modal', type, data);
			},
			selectOptionFilter(filter, value) {
				if(typeof value == 'string'){
					if(this[filter].includes(value)){
						this[filter].splice(this[filter].indexOf(value), 1)
					}else{
						this[filter].push(value)
					}
					
				} else {
					if(this[filter].includes(parseInt(value))){
					
						this[filter].splice(this[filter].indexOf(parseInt(value)), 1)
					}else{
						this[filter].push(parseInt(value))
					}
					
				}

				this.addFilterToRouteQuery(filter)
        	},
			selectUniqueOptionFilter(filter, value) {
				if(filter == 'selectedFromStatus') {
					if(this[filter] == value){
						this[filter] = ''
					}else{
						this[filter] = value
					}
				} else {
					this[filter] = value
				}
				if(filter == 'selectedSoft') { this.selectedPackages = [] }

				this.addFilterToRouteQuery(filter)
        	},
        	clearFilter(filter){
        		this[filter] = []

				this.addFilterToRouteQuery(filter)
			},
        	addFilterToRouteQuery(filterName){
				var dataQuery = Object.assign({}, this.$route.query);

				if(filterName == 'selectedFromStatus'){ dataQuery['from_status'] = this.selectedFromStatus.toString(); }
				if(filterName == 'selectedInstanceStatus'){ dataQuery['status'] = this.selectedInstanceStatus.toString(); }
				if(filterName == 'selectedPackages'){ dataQuery['packages'] = this.selectedPackages.toString(); }
				if(filterName == 'selectedSoft'){ 
					dataQuery['soft'] = this.selectedSoft.toString();
					delete dataQuery['selectedPackages']
				}
				if(filterName == 'date'){ 
					dataQuery['start_date'] = moment(this.dateRange.startDate).format('DD-MM-YYYY');
					dataQuery['end_date'] = moment(this.dateRange.endDate).format('DD-MM-YYYY');
				}

				Object.keys(dataQuery).map(function(key, value) {
					if(dataQuery[key] == ""){
						delete dataQuery[key]
					}
				});

				this.$router.push({query : dataQuery });

				this.getReportDaily()
			},
			updateRangeFilter(obiec){
                this.loadedList = false

				this.addFilterToRouteQuery('date')
			},
			syncList(){
				var buttonName = `buttonSync`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				btnSubmit.disabled = true

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')

				axios.get('/overall')
				.then(() => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('completed')
							btnSubmitText.innerHTML = this.$t('admin_users.sync')
							btnSubmit.disabled = false

							// Aici trebuie sa apelam functia care va face refresh
						}, 300)
					})
				})
				.catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('admin_users.sync')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				})
			},
			queryObject(){
				var paramsCall = {};
				
				paramsCall.soft = this.selectedSoft
				paramsCall.status = this.selectedInstanceStatus
				paramsCall.packages = this.selectedPackages.length ? this.selectedPackages :  this.$store.getters['packages/getBySoft'](this.selectedSoft).packages.map(el => { return el.unique_name })
				paramsCall.start_date = moment(this.dateRange.startDate).format('YYYY-MM-DD')
				paramsCall.end_date = moment(this.dateRange.endDate).format('YYYY-MM-DD')

				if(this.selectedFromStatus){
					paramsCall.from_status = this.selectedFromStatus
				}

				return paramsCall;
			}
        }
    }
</script>

<style lang="scss">
    @import "../../styles/admin/report_daily.scss"
</style>